<template>
    <div>
        <h1>qAssess <em>Preview Edition</em></h1>
        <b-card class="login" header-tag="header">
            <template #header>
                Login
            </template>
            <b-card-text>
                <b-form>
                    <b-form-group
                        id="input-group-1"
                        label="Enter Access Code:"
                        label-for="input-accessCode"
                        description=""
                    >
                    <b-form-input
                        id="input-accessCode"
                        v-model="accessCode"
                        type="text"
                        required
                        placeholder="____-____-____"
                        v-mask="'NNNN-NNNN-NNNN'"
                        autofocus
                        v-on:input="accessCodeError = null"
                        ></b-form-input>
                    </b-form-group>
                    <div class="text-danger" v-if="accessCodeError != null" v-html="accessCodeError">

                    </div>            
                </b-form>
            </b-card-text>
            <b-button variant="info" @click.prevent="login" class="mt-2">Login</b-button>
        </b-card>
        
        <hr>
        <div class="px-4">
            <b-card class="preview-note mt-0" header="Preview Note"
                header-text-variant="white"
                header-tag="header"
                header-bg-variant="secondary">
                <b-card-text class="text-left">
                    For this <em>Preview Edition</em>, you may enter any 12 characters to login (e.g. <span class="text-nowrap">1111-1111-1111</span>).
                    To ensure your browser will work properly, refer to our <b-link to="browser">Browser Compatibility</b-link> list.
                    <br><br>
                    The <em>Live Edition</em> will require your instructor-assigned 12 character random Access Code.
                </b-card-text>
            </b-card>  
        </div>        
    </div>
</template>

<script>

export default {
    name: 'Login',
    data() {
        return {        
            accessCode: null,
            accessCodeError: null
        }
    },
    methods: {  
        login() {
            let ac = this.accessCode.trim().replaceAll('-','');
            if (ac.length == 0) {
                this.accessCodeError = "Access Code is required<br>For Preview &ndash; enter any 12 digits"
                return false;                
            }
            else if (ac.length != 12) {
                this.accessCodeError = "Access Code must be 12 characters"
                return false;
            }                

            document.cookie = "qAssess-Preview-Login=true;SameSite=Strict;Secure";
            this.$router.push({ name: 'Home' });
        }
    }
}
</script>

<style scoped>
    h1 {
        font-size: 2rem;
        margin: 70px auto 20px auto;
    }
    .card {
        margin: 10px auto 80px auto;

    }
    .card-header {
        background-color: black;
        color: white;
        font-weight: bold;
        font-size: 1.3rem;
    }
    .card-body {
        background-color: #f7f7f7;
        color: #000;
    }
    .login {
        width: 400px;
    }
    .preview-note {
        min-width: 400px;
        max-width: 670px;
    }
    .preview-note .card-header {
        padding: 4px 20px;
    }
    #input-accessCode {
        font-family: 'Courier New', Courier, monospace;
        width: 250px;
        margin: 0 auto;
        font-size: 1.6rem;
        padding: 0.5rem 0.5rem;
    }
    ::placeholder { 
    color: #B0B0B0;
    opacity: 1; 
    }    
</style>